import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
		
	body {
		font-family: 'Montserrat', sans-serif;
		font-size: 10px;
		color: #F2F2F2;
		background-color: #202124; // You can remove this if you've already set the background color
	}
  
	a {
		color: #0ea5e9;
		text-decoration: none;

		&:hover {
			color: #4bc0f4;
		}
	}
`;

export default GlobalStyle;
