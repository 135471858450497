import React from "react";
import CookieConsent from "react-cookie-consent"; // Import CookieConsent
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CompareInterest from "./components/CompareInterests";
import CompareSkill from "./components/CompareSkill";
import CompleteQuestionnaire from "./components/CompleteQuestionnaire";
import GlobalStyle from "./components/GlobalStyles"; // Import GlobalStyle
import Interests from "./components/Interests";
import Layout from "./components/Layout";
import NotFound from "./components/NotFound";
import Result from "./components/Result";
import Skills from "./components/Skill";

function App() {
  return (
    <Router>
      <GlobalStyle /> {/* Use GlobalStyle */}
      <CookieConsent
        location="bottom"
        buttonText="Prihvaćam"
        cookieName="userConsentCookie"
        style={{ background: "#2B373B", fontSize: "0.75rem" }}
        buttonStyle={{ background: "#0ea5e9", fontSize: "1rem" }}
        expires={150}
      >
        Kolačiće upotrebljavamo za spremanje postavki i praćenje vaših
        aktivnosti. Koristimo alate koji analiziraju kako koristite stranicu i
        izrađuju anonimizirane izvještaje za poboljšanje korisničkog iskustva.
        Moguće je da vam se pokažu oglasi na trećim stranicama zbog korištenja
        naše stranice.
      </CookieConsent>
      <Routes>
        <Route
          path="/results/:attemptId"
          element={
            <Layout>
              <Result />
            </Layout>
          }
        />
        <Route
          path="/interests/:attemptId"
          element={
            <Layout>
              <Interests />
            </Layout>
          }
        />
        <Route
          path="/compare_interests/:attemptId/:voucherId"
          element={
            <Layout>
              <CompareInterest />
            </Layout>
          }
        />
        <Route
          path="/compare_skill/:attemptId/:voucherId"
          element={
            <Layout>
              <CompareSkill />
            </Layout>
          }
        />
        <Route
          path="/skills/:attemptId"
          element={
            <Layout>
              <Skills />
            </Layout>
          }
        />
        <Route
          path="/"
          element={
            <Layout>
              <CompleteQuestionnaire />
            </Layout>
          }
        />
        <Route
          path="*"
          element={
            <Layout>
              <NotFound />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
