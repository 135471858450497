import React, { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";
import {
  ActionButton,
  ButtonsWrapper,
  Checkbox,
  CheckboxContainer,
  ContentContainer,
  TopText,
  VerticalLabel,
} from "./styledComponents";

const StartPage = ({ attemptId, onNext }) => {
  const [isFirstCheckboxChecked, setFirstCheckboxChecked] = useState(false);
  const [isSecondCheckboxChecked, setSecondCheckboxChecked] = useState(false);
  const [isThirdCheckboxChecked, setThirdCheckboxChecked] = useState(false);
  const textParts = [
    {
      content: ["Dobro došli!"],
    },
    {
      content: [
        "Na ovim stranicama moći ćete istražiti koji bi Vam obrazovni programi ponuđeni unutar sustava ",
        {
          type: "link",
          text: "vauceri.hzz.hr",
          url: "https://vauceri.hzz.hr",
        },
        " bili prikladni, s obzirom na Vaše interese i vještine.",
      ],
    },
    {
      content: [
        "Da biste dobili popis prikladnih obrazovnih programa, odvojite 10-ak minuta svoga vremena i ispunite ovaj upitnik. Na početku trebate odgovoriti na nekoliko općih pitanja o Vama, a zatim procijeniti koliko Vam se sviđaju različita zanimanja i radne aktivnosti te na kraju koliko ste vješti u različitim tipovima radnih zadataka.",
      ],
    },
    {
      content: [
        "Kada završite ispunjavanje upitnika, dobit ćete popis obrazovnih programa unutar sustava vaučera koji bi Vam mogli odgovarati. Za svaki ponuđeni obrazovni program dobit ćete informaciju o tome koliko je sukladan Vašim interesima i vještinama, vidjet ćete s kojim je zanimanjem povezan i kojem sektoru svijeta rada pripada. Također, za opsežniji profesionalni savjet moći ćete vidjeti i povratnu informaciju o svojim interesima i vještinama.",
      ],
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paragraphs = textParts.map((part, index) => (
    <p key={index}>
      {part.content.map((content, i) => {
        if (typeof content === "string") {
          return <span key={i}>{content}</span>;
        } else if (content.type === "link") {
          return (
            <a
              key={i}
              href={content.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {content.text}
            </a>
          );
        }
        return null;
      })}
    </p>
  ));
  // Function to navigate to the next page or perform any other action
  const handleNextClick = () => {
    // Redirect to another page or perform another action
    console.log("Moving to the next step...");
    if (isFirstCheckboxChecked && isSecondCheckboxChecked) {
      onNext(); // Use the onNext prop passed from the parent component
    }
  };

  return (
    <div>
      {/* <TitleText>Naslov</TitleText> */}
      <ContentContainer>
        <TopText>{paragraphs}</TopText>
      </ContentContainer>
      <ContentContainer>
        <TopText>
          Označite jeste li razumjeli ove upute i jeste li suglasni s
          ispunjavanjem:
        </TopText>
        <CheckboxContainer>
          <Checkbox
            id="firstCheckbox"
            checked={isFirstCheckboxChecked}
            onChange={() => setFirstCheckboxChecked(!isFirstCheckboxChecked)}
          />
          <VerticalLabel htmlFor="firstCheckbox">
            Razumio/razumjela sam ove upute.
          </VerticalLabel>{" "}
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            id="secondCheckbox"
            checked={isSecondCheckboxChecked}
            onChange={() => setSecondCheckboxChecked(!isSecondCheckboxChecked)}
          />
          <VerticalLabel htmlFor="secondCheckbox">
            Suglasan/suglasna sam s ispunjavanjem ovog upitnika.
          </VerticalLabel>
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            id="thirdCheckbox"
            checked={isThirdCheckboxChecked}
            onChange={() => setThirdCheckboxChecked(!isThirdCheckboxChecked)}
          />
          <VerticalLabel htmlFor="thirdCheckbox">
            Pristajem na obradu svojih podataka u skladu s GDPR-om.
          </VerticalLabel>
        </CheckboxContainer>
        <ButtonsWrapper>
          <ActionButton
            onClick={handleNextClick}
            disabled={
              !(
                isFirstCheckboxChecked &&
                isSecondCheckboxChecked &&
                isThirdCheckboxChecked
              )
            }
          >
            {"Sljedeći"}
          </ActionButton>
        </ButtonsWrapper>
        <ProgressBar currentStep={1} />
      </ContentContainer>
    </div>
  );
};

export default StartPage;
