import { useRef } from "react";

const scrollOptions = {
  block: "nearest",
  inline: "center",
  behavior: "smooth",
};

const useScroll = (className) => {
  const ref = useRef();

  const scrollTo = (name, options = {}) => {
    const mergedOptions = { ...scrollOptions, ...options };
    if (name || className) {
      const element = document?.querySelector(name || className);
      if (element) element.scrollIntoView(mergedOptions);
    } else if (ref.current) ref.current.scrollIntoView(mergedOptions);
  };

  return { ref, scrollTo };
};

export default useScroll;
