import React, { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";

import DOMPurify from "dompurify"; // Import the DOMPurify library
import { styled } from "styled-components";
import { useScroll } from "../hooks";
import {
  ActionButton,
  ButtonsWrapper,
  Line,
  PersonalQuestionContainer,
  QuestionText,
  RadioButton,
  TextInput,
  TitleText,
  VerticalLabel,
  VerticalOptionsContainer,
} from "./styledComponents";

const Option = styled.label`
  display: flex;
  align-items: center;
`;

const PersonalQuestionText = styled(QuestionText)`
  margin-bottom: 1rem;
  font-size: 1rem;
`;

const PersonalQuestionsPage = ({ attemptId, onNext, onBack }) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [animationKey, setAnimationKey] = useState(Date.now());
  const [questionColor, setquestionColor] = useState([]);

  const scroller = useScroll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function submitOptionalAnswers(optionalAnswers, attemptId) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/submit_optional_personal_answers/?attempt_uuid=${attemptId}`;

    const sanitizedOptionalAnswers = {}; // Object to store sanitized answers
    // Sanitize user inputs before submission
    for (const [questionOrder, answerValue] of Object.entries(
      optionalAnswers
    )) {
      sanitizedOptionalAnswers[questionOrder] = DOMPurify.sanitize(answerValue);
    }
    console.log(sanitizedOptionalAnswers);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(sanitizedOptionalAnswers), // Adjust this according to your backend's expected format
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        console.log("Successfully submitted optional answers!");
      }
    } catch (error) {
      console.error("Error submitting optional answers:", error);
    }
  }

  const handleNext = async () => {
    let allAnswered = true;
    let newQuestionColor = [...questionColor];

    // for (let i = 1; i < questions.length + 1; i++) {
    //   if (answers[i] === undefined) {
    //     newQuestionColor[i] = "red";
    //     allAnswered = false;
    //   }
    // }

    // if (!allAnswered) {
    //   setquestionColor(newQuestionColor);
    //   setAnimationKey(Date.now()); // Reset the animation key
    //   return;
    // }

    for (let question of questions) {
      if (answers[question.order] === undefined && !question.optional) {
        newQuestionColor[question.order] = "red"; // Mark unanswered non-optional questions
        allAnswered = false;
      } else {
        newQuestionColor[question.order] = ""; // Ensure answered or optional questions are not marked
      }
    }

    if (!allAnswered) {
      setquestionColor(newQuestionColor);
      setAnimationKey(Date.now()); // Reset the animation key
      return;
    }

    // Initialize the answers_to_submit object
    let answers_to_submit = {};

    // Iterate over the answers state
    for (let questionOrder in answers) {
      let answerValue = answers[questionOrder];

      // Find the corresponding question to check if it's optional
      let correspondingQuestion = questions.find(
        (q) => q.order === parseInt(questionOrder)
      );

      // Proceed only if the question is not optional
      if (correspondingQuestion && !correspondingQuestion.optional) {
        if (
          correspondingQuestion.Answers &&
          correspondingQuestion.Answers.length > 0
        ) {
          // If the question has predefined answers, find the matching one
          let matchingAnswer = correspondingQuestion.Answers.find(
            (answer) => answer.text === answerValue
          );

          if (matchingAnswer) {
            answers_to_submit[correspondingQuestion.id] = matchingAnswer.id;
          }
        } else {
          // If the question doesn't have predefined answers, set the answer text directly
          answers_to_submit[correspondingQuestion.id] = answerValue;
        }
      }
    }
    console.log("BBB");

    let optionalAnswers = {};

    for (let question of questions) {
      if (question.optional && answers[question.order] !== undefined) {
        // Collect answered optional questions
        optionalAnswers[question.id] = answers[question.order];
      }
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/submit_personal_answers/?attempt_uuid=${attemptId}`;

    const sanitizedAnswers = {}; // Object to store sanitized answers
    // Sanitize user inputs before submission
    for (const [questionOrder, answerValue] of Object.entries(
      answers_to_submit
    )) {
      sanitizedAnswers[questionOrder] = DOMPurify.sanitize(answerValue);
    }
    console.log("CCC");

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(sanitizedAnswers), // Submit the processed answers
      });
      console.log({ response });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        console.log("Successfully submitted answers!");
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
    }
    console.log("AAA");

    if (Object.keys(optionalAnswers).length > 0) {
      console.log(optionalAnswers);
      submitOptionalAnswers(optionalAnswers, attemptId);
    }
    console.log("AAA");
    onNext();
  };

  useEffect(() => {
    scroller.scrollTo(".unanswered", { block: "center" });
    // eslint-disable-next-line
  }, [questionColor]);

  useEffect(() => {
    async function fetchQuestions() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/personal_questions`); // replace with your back-end endpoint
        const responseJSON = await response.json();

        const sortedQuestions = responseJSON.sort((a, b) => a.order - b.order);
        setQuestions(sortedQuestions);
        console.log(sortedQuestions);

        // Now make the second API call for personal answers
        const answersResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/get_personal_answers?attempt_uuid=` + attemptId,
          {
            method: "GET",
            headers: {
              accept: "application/json",
            },
          }
        );
        const answersJSON = await answersResponse.json();

        // Process the answers as needed here.
        // For the sake of this example, let's say we'll map it to an object for easier access
        let processedAnswers = {};
        answersJSON.forEach((item) => {
          if (item.answer_id === null) {
            handleInputChange(item.order, item.text);
          } else {
            handleInputChange(item.order, item.text);
          }
          // processedAnswers[item.order] = {
          //   answer_id: item.answer_id,
          //   text: item.text,
          // };
        });

        // setAnswers(processedAnswers); // Set the answers state
        console.log(processedAnswers);
      } catch (error) {
        console.error("Error fetching personal questions:", error);
      }
    }

    fetchQuestions();
  }, []);

  const handleBack = () => {
    onBack();
  };

  const handleInputChange = (questionOrder, answerTextOrIndex) => {
    // Assuming `questions` is available in your component's scope
    const question = questions.find((q) => q.order === questionOrder);

    // If the question is optional, check if the input is numeric or empty
    if (question && question.optional) {
      if (answerTextOrIndex !== "" && !/^\d*$/.test(answerTextOrIndex)) {
        // If the input is not numeric and not empty, ignore this input
        return;
      }
      if (answerTextOrIndex.length > 13) {
        answerTextOrIndex = answerTextOrIndex.substring(0, 13);
      }
    }
    setAnswers((prev) => ({
      ...prev,
      [questionOrder]: answerTextOrIndex === "" ? undefined : answerTextOrIndex,
    }));
  };


  return (
    <div>
      <TitleText>Odgovorite, molimo Vas, na sljedeća pitanja:</TitleText>
      {questions.map((question, index) => {
        const isUnanswered =
          answers[index + 1] === undefined &&
          questionColor[index + 1] === "red";
        return (
          <PersonalQuestionContainer
            key={question.id + -"" + animationKey}
            isUnanswered={isUnanswered}
            className={isUnanswered ? "unanswered" : ""}
          >
            <PersonalQuestionText>{question.text}</PersonalQuestionText>
            {question.Answers && question.Answers.length > 0 ? (
              <VerticalOptionsContainer>
                {question.Answers.map((answer) => (
                  <Option key={answer.order}>
                    <RadioButton
                      id={`question-${question.order}-answer-${answer.order}`} // Unique ID using question and answer order
                      name={question.order.toString()}
                      value={answer.text}
                      checked={answers[question.order] === answer.text}
                      onChange={() =>
                        handleInputChange(question.order, answer.text)
                      }
                    />
                    <VerticalLabel htmlFor={`question-${question.order}-answer-${answer.order}`}>{answer.text}</VerticalLabel>
                  </Option>
                ))}
              </VerticalOptionsContainer>
            ) : (
              <TextInput
                value={answers[question.order] || ""}
                onChange={(e) =>
                  handleInputChange(
                    question.order,
                    DOMPurify.sanitize(e.target.value)
                  )
                }
              />
            )}
            <Line style={{ marginTop: 15 }} />{" "}
          </PersonalQuestionContainer>
        );
      })}
      <ButtonsWrapper>
        <ActionButton onClick={handleBack}>Natrag</ActionButton>
        <ActionButton onClick={handleNext}>Sljedeći</ActionButton>
      </ButtonsWrapper>
      <ProgressBar currentStep={2} />
    </div>
  );
};

export default PersonalQuestionsPage;
