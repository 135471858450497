// import styled from "styled-components";

// const Step = styled.div`
//   display: flex;
//   align-items: center;
//   width: 25%; // give the Step container a width
// `;

// const ProgressBarContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 30%;
//   position: absolute;
//   left: 35%; /* center it on the screen */
// `;

// const Circle = styled.div`
//   width: 54px;
//   height: 54px;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 24px;
//   background: ${(props) => (props.completed ? "#0EA5E9" : "#454649")};
//   color: ${(props) => (props.completed ? "white" : "black")};
//   margin-right: 10px; // add some margin to separate from the Line
// `;

// const Line = styled.div`
//   flex-grow: 1;
//   height: 18px;
//   background: ${(props) => (props.completed ? "#0EA5E9" : "#454649")};
//   border-radius: 8px;
//   position: relative;
//   margin-left: 10px; // add some margin to separate from the Circle
// `;

// const steps = [1, 2, 3, 4, 5, 6];
// function ProgressBar({ currentStep }) {
//   return (
//     <ProgressBarContainer>
//       {steps.map((step, index) => (
//         <Step key={step}>
//           <Circle completed={step <= currentStep}>{step}</Circle>
//           {index < steps.length - 1 && <Line completed={step < currentStep} />}
//         </Step>
//       ))}
//     </ProgressBarContainer>
//   );
// }

// export default ProgressBar;

import React, { Fragment } from "react";
import styled from "styled-components";

const smallCircleSize = "26px";
const largeCircleSize = "30px";

const ProgressBarContainer = styled.div`
  position: absolute;
  cursor: default;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  max-width: 760px;
  left: 50%;
  transform: translate(-50%, calc((40px - ${largeCircleSize}) / 2));

  @media (max-width: 768px) {
    transform: translate(-50%, calc((20px - ${largeCircleSize}) / 2));
  }
`;

const Circle = styled.div`
  z-index: 1;
  width: ${(props) => (props.isSubStep ? smallCircleSize : largeCircleSize)};
  height: ${(props) => (props.isSubStep ? smallCircleSize : largeCircleSize)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: ${(props) => (props.isSubStep ? "12px" : "14px")};
  background: ${(props) => {
    if (props.isSubStep && props.completed) {
      return "#c9731d"; // Different color for completed substeps
    } else if (props.completed) {
      return "#0EA5E9"; // Color for completed main steps
    } else {
      return "#454649"; // Default grey color for incomplete steps
    }
  }};
  color: ${(props) => (props.completed ? "white" : "#ccc")};
  transform: scale(1.1);
`;

const Line = styled.div`
  position: relative;
  z-index: 0;
  flex-grow: 1;
  height: 10px;
  width: ${(props) =>
    props.isSubStep ? "10px" : "auto"}; // Limit width for substep lines
  background: ${(props) => {
    if (props.isSubStep && props.completed) {
      return "#c9731d"; // Different color for completed substeps
    } else if (props.completed) {
      return "#0EA5E9"; // Color for completed main steps
    } else {
      return "#454649"; // Default grey color for incomplete steps
    }
  }};
`;

const steps = [
  { mainStep: 1 },
  { mainStep: 2 },
  {
    mainStep: 3,
  },
  {
    mainStep: 4,
    subSteps: [1, 2], // smaller circles
  },
];

function ProgressBar({ currentStep, page }) {
  let stepValue = currentStep;
  if (currentStep === 4 && page !== undefined) {
    stepValue += page <= 1 ? 0.1 : 0.2;
  }
  return (
    <ProgressBarContainer>
      {steps.map((step, index) => {
        // Check if the current step has subSteps
        const hasSubSteps = typeof step === "object" && step.subSteps;

        // Calculate the completed state for main steps
        const isMainStepCompleted = step.mainStep <= Math.floor(stepValue);

        return (
          <Fragment key={step.mainStep}>
            {/* {hasSubSteps && <Line isSubStep completed={isMainStepCompleted} />} */}
            {/* Render main step */}
            <Circle completed={isMainStepCompleted}>{step.mainStep}</Circle>
            {/* If the current step has subSteps, map and render them */}
            {hasSubSteps &&
              step.mainStep === Math.floor(stepValue) &&
              step.subSteps.map((subStep) => (
                <Fragment key={subStep}>
                  <Line
                    isSubStep
                    completed={step.mainStep + subStep / 10 <= stepValue}
                  />
                  <Circle
                    key={subStep}
                    isSubStep
                    completed={step.mainStep + subStep / 10 <= stepValue}
                  >
                    {subStep}
                  </Circle>
                </Fragment>
              ))}

            {/* Render the line between steps */}
            {index < steps.length - 1 && (
              <Line completed={step.mainStep < Math.floor(stepValue)} />
            )}
          </Fragment>
        );
      })}
    </ProgressBarContainer>
  );
}

export default ProgressBar;
