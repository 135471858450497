import React from "react";
import styled from "styled-components";
import { ActionButton, ButtonsWrapper, TopText } from "./styledComponents";

const ModalBackdrop = styled.div`
  z-index: 999999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #2a2b2f; // Light grayish background
  padding: 2rem;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Adds a subtle shadow for depth
`;

const ActionButtons = styled(ButtonsWrapper)`
  margin: 0;
  justify-content: center;
  gap: 10px;
`;

const CancelButton = styled(ActionButton)`
  background-color: #e27a12; // The color you provided earlier
  border-color: #e27a12;
  &:hover {
    background-color: #bf6a0f; // A bit darker on hover
  }
`;

export default function SubmitModal({ isOpen, onSubmit, onCancel }) {
  if (isOpen) {
    return (
      <ModalBackdrop>
        <ModalContent>
          <TopText>Jeste li sigurni da želite poslati svoje odgovore?</TopText>
          <ActionButtons>
            <CancelButton onClick={onCancel}>Otkaži</CancelButton>
            <ActionButton onClick={onSubmit}>Pošalji</ActionButton>
          </ActionButtons>
        </ModalContent>
      </ModalBackdrop>
    );
  } else {
    return null;
  }
}
