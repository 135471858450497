import React from "react";
import styled from "styled-components";
import {
  ContentContainer,
  TopText,
} from "./styledComponents";

const ErrorCode = styled.div`
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1rem;
`

const NotFound = () => {
  return (
    <ContentContainer>
      <TopText>
          <ErrorCode>404</ErrorCode>
          Ova stranica ne postoji ili je premještena.
      </TopText>
    </ContentContainer>
  );
};

export default NotFound;
