import React from "react";
import styled from "styled-components";
import headerImage from "../images/headerImage.png";

const HeaderContainer = styled.div`
  background-image: url(${headerImage});
  background-size: cover;
  background-position: center;
  height: 25vh; /* change this to change the height of the header */
`;

function Header() {
  return <HeaderContainer />;
}

export default Header;
