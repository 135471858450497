import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
// import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";
import {
  ContentContainer,
  InterestPage,
  RadarContainer,
  TitleText,
  TopText,
} from "./styledComponents";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const firstTextParts = [
  {
    content: [
      "Na donjoj slici možete vidjeti koliko imate izražene pojedine tipove interesa – tehničke, istraživačke, umjetničke, društvene, poduzetničke i službeničke.",
    ],
  },
  {
    content: [
      "Pogledajte donji prikaz i proučite vlastite interese. Tipovi interesa koji su najudaljeniji od središta su Vam najizraženiji i takva područja rada bi Vam najbolje odgovarala. Stavite kursor na vaš rezultat na nekom od interesa na grafu (označen točkom) i vidjeti ćete opis tog tipa interesa i prikladna područja rada.",
    ],
  },
];

const secondTextParts = [
  {
    id: "Tehnički interesi",
    content: [
      "Tehnički interesi - tehnička zanimanja",
      "Rad uključuje projektiranje, izgradnju ili",
      "popravak opreme, materijala ili struktura, tjelesno",
      "aktivan rad ili rad na otvorenom. Tehnička zanimanja",
      "često su povezana s inženjerstvom, mehanikom i",
      "elektronikom, građevinarstvom, obradom drva, transportom,",
      "upravljanjem strojevima, poljoprivredom, brigom za",
      "životinje, osiguravanjem ljudi ili imovine, odnosno",
      "fizičkim radom koji ponekad zahtijeva ručnu spretnost",
      "i izdržljivost.",
    ],
  },
  {
    id: "Istraživački interesi",
    content: [
      "Istraživački interesi – istraživačka zanimanja",
      "Rad uključuje proučavanje i istraživanje prirodnih",
      "pojava, neživih objekata, živih organizama, ljudskog",
      "ponašanja, bolesti ili drugih oblika oštećenja i",
      "promjena. Istraživačka zanimanja često su povezana s",
      "prirodnim, medicinskim ili društvenim znanostima, a",
      "mogu se pronaći i u područjima humanističkih znanosti,",
      "matematike/statistike, informacijske tehnologije ili",
      "zdravstva.",
    ],
  },
  {
    id: "Umjetnički interesi",
    content: [
      "Umjetnički interesi – umjetnička zanimanja",
      "Rad uključuje stvaranje originalnih vizualnih umjetničkih",
      "djela, pisanih tekstova, glazbe ili performansa, kao i",
      "primjenu umjetničkih principa na dizajn različitih",
      "predmeta i materijala. Umjetnička zanimanja često su",
      "povezana s vizualnim umjetnostima, primijenjenim",
      "umjetnostima i dizajnom, izvedbenim umjetnostima,",
      "glazbom, kreativnim pisanjem ili novim medijima.",
    ],
  },
  {
    id: "Društveni interesi",
    content: [
      "Društveni interesi – društvena zanimanja",
      "Rad uključuje pomaganje, podučavanje, savjetovanje",
      "ili pružanje usluga drugima. Društvena",
      "zanimanja često su povezana sa psihološkom pomoći,",
      "zdravstvenom ili socijalnom skrbi, podučavanjem i",
      "obrazovanjem, osobnim uslugama ili duhovnom potporom.",
    ],
  },
  {
    id: "Poduzetnički interesi",
    content: [
      "Poduzetnički interesi – poduzetnička zanimanja",
      "Rad uključuje upravljanje, pregovaranje, marketing ili",
      "prodaju, obično u poslovnom okruženju, ili vođenje i",
      "savjetovanje ljudi u političkim i pravnim situacijama.",
      "Poduzetnička zanimanja često se povezuju s poslovnim",
      "inicijativama, trgovinom, marketingom, oglašavanjem,",
      "financijama, menadžmentom, upravljanjem ljudskim potencijalima,",
      "javnim nastupima, politikom ili pravom.",
    ],
  },
  {
    id: "Službenički interesi",
    content: [
      "Službenički interesi – službenička zanimanja",
      "Rad uključuje poštovanje procedura i propisa pri",
      "organiziranju informacija ili podataka, obično u",
      "poslovnom okruženju. Službenička zanimanja često su",
      "povezana s uredskim poslom, računovodstvom,",
      "matematikom/statistikom, informacijskom tehnologijom,",
      "financijama ili ljudskim resursima.",
    ],
  },
];

function Interest() {
  const { attemptId } = useParams();
  const [chartData, setChartData] = useState(null);

  const firstParagraphs = firstTextParts.map((part, index) => (
    <p key={index}>
      {part.content.map((content, i) => {
        if (typeof content === "string") {
          return <span key={i}>{content}</span>;
        } else if (content.type === "link") {
          return (
            <a
              key={i}
              href={content.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {content.text}
            </a>
          );
        }
        return null;
      })}
    </p>
  ));

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/get_interest/?attempt_uuid=${attemptId}`
        );
        const responseJSON = await response.json();
        const { labels, data } = responseJSON;

        const roundedData = data.map((value) => Math.round(value));

        const formattedData = {
          labels: labels,
          datasets: [
            {
              label: "Rezultat",
              data: roundedData,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)",
              borderWidth: 2,
              hoverBackgroundColor: "rgba(75,192,192,0.4)",
              hoverBorderColor: "rgba(75,192,192,1)",
            },
          ],
        };
        setChartData(formattedData);
      } catch (error) {
        console.error("Error fetching interests:", error);
      }
    }

    fetchData();
  }, [attemptId]);

  /*
  const options = {
    series: [
      {
        name: "Radar Series 1",
        data: [45, 52, 38, 24, 33, 10],
      },
      {
        name: "Radar Series 2",
        data: [26, 21, 20, 6, 8, 15],
      },
    ],
    labels: ["April", "May", "June", "July", "August", "September"],
  };
  */

  return (
    <div>
      <TitleText>Vaši interesi</TitleText>
      <ContentContainer>
        <TopText>{firstParagraphs}</TopText>
      </ContentContainer>
      <InterestPage>
        <ContentContainer style={{ display: "flex", justifyContent: "center" }}>
          <RadarContainer>
            {chartData ? (
              <Radar
                data={chartData}
                options={{
                  scales: {
                    r: {
                      min: 0,
                      max: 100,
                      grid: {
                        color: "#ccc",
                      },
                      pointLabels: {
                        color: "#fff",
                        font: {
                          size: 18,
                        },
                      },
                    },
                  },
                  plugins: {
                    tooltip: {
                      backgroundColor: "#202124",
                      padding: 15,
                      titleColor: "#ccc",
                      bodyColor: "#ccc",
                      footerColor: "#ccc",
                      titleFont: {
                        size: 18,
                      },
                      bodyFont: {
                        size: 16,
                      },
                      footerFont: {
                        size: 14,
                      },
                      callbacks: {
                        footer: (items) => {
                          const { label } = items[0];

                          if (!label) return null;

                          const { content } = secondTextParts.find(
                            (item) => item.id === label
                          );

                          return content;
                        },
                      },
                    },
                    legend: {
                      labels: {
                        color: "#ccc",
                        font: {
                          size: 18,
                        },
                      },
                    },
                  },
                }}
              />
            ) : (
              <p>Loading...</p>
            )}
          </RadarContainer>
        </ContentContainer>
      </InterestPage>
    </div>
  );
}

export default Interest;
