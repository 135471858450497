import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { useScroll } from "../hooks";
import ProgressBar from "./ProgressBar";
import Questions from "./Questions";
import SubmitModal from "./SubmitModal";
import AllSameAnswersModal from "./allSameAnswers";
import {
  ActionButton,
  ButtonsWrapper,
  ContentContainer,
  OptionLabelContainer,
  OptionLabelText,
  QuestionPage,
  TopText,
} from "./styledComponents";

const Blank = styled.div``;

const OptionLabels = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
`;

const QuestionsContainer = styled(ContentContainer)`
  @media (max-width: 768px) {
    padding: 0;
    background: none;
    border: none;
  }
`;

const Questionnaire = ({ attemptId, onBack }) => {
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [highestPageVisited, setHighestPageVisited] = useState(0);
  const navigate = useNavigate();
  const [section, setSection] = useState(1); // State to handle section
  const [animationKey, setAnimationKey] = useState(Date.now());
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const scroller = useScroll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const questionsPerPage = 12;
  const questionsPerPageDict = { 1: 12, 2: 12 };

  const sectionData = {
    1: {
      labels: [
        "Uopće mi se ne sviđa",
        "Ne sviđa mi se",
        "Niti mi se sviđa, niti mi se ne sviđa",
        "Sviđa mi se",
        "Jako mi se sviđa",
      ],
      topText: {
        content: [
          "Pred Vama se nalazi popis različitih radnih aktivnosti. Pročitajte svaku aktivnost i ocijenite koliko Vam se sviđa tako što ćete označiti jedan broj na skali od 1 do 5, čija su značenja opisana.",
          {
            type: "list",
            items: [
              ["1 - Uopće mi se ne sviđa"],
              ["2 - Ne sviđa mi se"],
              ["3 - Niti mi se sviđa, niti mi se ne sviđa"],
              ["4 - Sviđa mi se"],
              ["5 - Jako mi se sviđa"],
            ],
          },
          "Odgovarajte iskreno i slijedite svoje interese. Koliko Vam se sviđaju sljedeće radne aktivnosti, odnosno biste li se voljeli njima baviti?",
        ],
      },
    },
    2: {
      labels: [
        "Nemam razvijenu ovu vještinu",
        "Ovu vještinu imam slabo razvijenu",
        "Ovu vještinu imam donekle razvijenu",
        "Ovu vještinu imam prilično dobro razvijenu",
        "Ovu vještinu imam odlično razvijenu",
      ], // Update labels as required
      topText: {
        content: [
          "Različiti poslovi zahtijevaju različite vještine, poput komuniciranja s drugim ljudima, tehničkih znanja ili upravljanja vremenom. Niže su navedene neke takve vještine. Pročitajte ih i za svaku ocijenite u kojoj je mjeri je ona kod vas razvijena. Pri tome koristite skalu od 1 do 5.",
          {
            type: "list",
            items: [
              ["1 - Nemam razvijenu ovu vještinu"],
              ["2 - Ovu vještinu imam slabo razvijenu"],
              ["3 - Ovu vještinu imam donekle razvijenu"],
              ["4 - Ovu vještinu imam prilično dobro razvijenu"],
              ["5 - Ovu vještinu imam odlično razvijenu"],
            ],
          },
        ],
      },
    },
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/questions`); // replace with your back-end endpoint
      const questions = await response.json();

      setQuestions(questions);
      setAnswers(
        Array.from({ length: questions.length }, () => ({
          // answer: Math.ceil(Math.random() * 5), // FOR TESTING
          answered: false,
          color: "transparent",
        }))
      );
    };

    fetchQuestions();
  }, []);

  const handleAnswer = (questionIndex, answer) => {
    // let newAnswers = [...answers];
    const newAnswers = answers.map((answer) => ({
      ...answer,
      color: "transparent",
    }));
    newAnswers[questionIndex] = {
      ...newAnswers[questionIndex],
      answer: answer,
      answered: true,
    };
    setAnswers(newAnswers);
  };

  // const WarningModal = ({ isOpen, onConfirm }) => {
  //   return (
  //     <div style={{ display: isOpen ? 'block' : 'none' }}>
  //       <p>All questions have the same answers! Are you sure about your selections?</p>
  //       <button onClick={onConfirm}>OK</button>
  //     </div>
  //   );
  // };

  const handleNext = () => {
    const nextPage = page + 1;
    // const totalPages = Math.ceil(questions.length / questionsPerPage);
    const totalPages =
      2 +
      Math.ceil(
        (questions.length - 2 * questionsPerPageDict[1]) /
          questionsPerPageDict[2]
      );
    // const totalPages = 4;

    // const firstQuestionOnPage = page * questionsPerPage;
    let firstQuestionOnPage = 0;
    if (page < 2) {
      firstQuestionOnPage = page * questionsPerPageDict[1];
    } else {
      firstQuestionOnPage =
        2 * questionsPerPageDict[1] + (page - 2) * questionsPerPageDict[2];
    }

    // let lastQuestionOnPage = firstQuestionOnPage + questionsPerPage;
    let lastQuestionOnPage = Math.min(
      firstQuestionOnPage + questionsPerPageDict[section],
      questions.length
    );
    let allAnswered = true;
    let newAnswers = [...answers];
    // const isLastPage =
    //   page === Math.ceil(questions.length / questionsPerPage) - 1;
    const isLastPage = page === totalPages - 1;

    if (isLastPage) {
      const allSame = answers.every(
        (answer) => answer.answer === answers[0].answer
      );
      // lastQuestionOnPage =
      //   questions.length - (totalPages - 1) * questionsPerPage;
      // lastQuestionOnPage =
      // questions.length - (totalPages - 1) * questionsPerPageDict[section];
      if (allSame) {
        // Open the warning modal if all answers are the same
        setIsWarningModalOpen(true);
        return; // Return early to avoid any subsequent logic
      }
    }

    for (let i = firstQuestionOnPage; i < lastQuestionOnPage; i++) {
      if (!newAnswers[i] || newAnswers[i].answer === undefined) {
        newAnswers[i] = { ...newAnswers[i], color: "red" };
        allAnswered = false;
      }
    }

    if (!allAnswered) {
      setAnswers(newAnswers);
      setAnimationKey(Date.now()); // Reset the animation key
      return;
    }

    let newSection = 1;
    if (nextPage >= 2) {
      newSection = 2;
    }
    setSection(newSection);

    if (nextPage < totalPages) {
      setPage(nextPage);
    } else {
      setIsModalOpen(true);
    }

    scroller.scrollTo("#page-anchor", { block: "start" });
  };

  const handleBack = () => {
    const prevPage = page - 1;
    if (prevPage >= 0) {
      setPage(prevPage);
    } else if (prevPage === -1) {
      onBack();
    }
  };

  const handleSubmit = async () => {
    // Prepare answers in the required format
    const formattedAnswers = answers.map((answer, index) => ({
      question_id: questions[index].id,
      answer: answer.answer,
    }));

    // POST the data to the server
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/submit_answers/?attempt_uuid=${attemptId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(formattedAnswers),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
      return; // Exit the function in case of error
    }

    // Close the modal
    setIsModalOpen(false);

    // Redirect to the results page
    navigate(`/results/${attemptId}`);

    // Remove data from localStorage
    localStorage.removeItem("answers");
    localStorage.removeItem("attempt_id");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // const renderNextButton = () => {
  //   // const isLastPage =
  //   //   page === Math.ceil(questions.length / questionsPerPage) - 1;
  //   const isLastPage =
  //     page === Math.ceil(questions.length / questionsPerPageDict[section]) - 1;
  //   return (
  //     <ActionButton onClick={handleNext}>
  //       {isLastPage ? "Pošaljite odgovore" : "Sljedeći"}
  //     </ActionButton>
  //   );
  // };

  useEffect(() => {
    if (answers.find((answer) => answer.color === "red")) {
      scroller.scrollTo(".unanswered", { block: "center" });
    }
    // eslint-disable-next-line
  }, [answers]);

  const totalPages =
    2 +
    Math.ceil(
      (questions.length - 2 * questionsPerPageDict[1]) / questionsPerPageDict[2]
    );
  const isLastPage = page === totalPages - 1;

  let firstQuestionIndex = 0;
  if (page < 2) {
    firstQuestionIndex = page * questionsPerPageDict[1];
  } else {
    firstQuestionIndex =
      2 * questionsPerPageDict[1] + (page - 2) * questionsPerPageDict[2];
  }

  let lastQuestionIndex = Math.min(
    firstQuestionIndex + questionsPerPageDict[section],
    questions.length
  );

  function renderText(content) {
    if (typeof content === "string") {
      return <span>{content}</span>;
    } else if (content.type === "list") {
      return (
        <ul style={{ listStyleType: "none" }}>
          {content.items.map((listItem, index) => (
            <li key={index}>{listItem.map((item, i) => renderText(item))}</li>
          ))}
        </ul>
      );
    } else if (content.bold) {
      return <b>{content.text}</b>;
    } else {
      return <span>{content.text}</span>;
    }
  }

  // Use this logic inside the JSX of your component
  const topTextElements = sectionData[section].topText.content.map(
    (content, i) => {
      if (content.type === "list") {
        return <div key={i}>{renderText(content)}</div>;
      } else {
        return <div key={i}>{renderText(content)}</div>;
      }
    }
  );

  return (
    <QuestionPage id="page-anchor">
      {/* <TitleText>TEST</TitleText> */}
      <ContentContainer>
        <TopText>{topTextElements}</TopText>
      </ContentContainer>
      <QuestionsContainer>
        <OptionLabelContainer>
          <Blank />
          <OptionLabels>
            {sectionData[section].labels.map((label, index) => (
              <OptionLabelText key={index}>{label}</OptionLabelText>
            ))}
          </OptionLabels>
        </OptionLabelContainer>
        <Questions
          questions={questions}
          page={page}
          // questionsPerPage={questionsPerPage}
          // questionsPerPage={questionsPerPageDict[section]}
          startIndex={firstQuestionIndex}
          endIndex={lastQuestionIndex}
          answers={answers}
          handleAnswer={handleAnswer}
          animationKey={animationKey} // pass the animation key down
        />
        <ButtonsWrapper>
          <ActionButton onClick={handleBack}>Natrag</ActionButton>
          <ActionButton onClick={handleNext}>
            {isLastPage ? "Pošaljite odgovore" : "Sljedeći"}
          </ActionButton>{" "}
        </ButtonsWrapper>
        <ProgressBar currentStep={4} page={page} />
        <SubmitModal
          isOpen={isModalOpen}
          onSubmit={handleSubmit}
          onCancel={closeModal}
        />
        <AllSameAnswersModal
          isOpen={isWarningModalOpen}
          onConfirm={() => setIsWarningModalOpen(false)} // You can add any additional logic in this callback if needed
        />
      </QuestionsContainer>
    </QuestionPage>
  );
};

export default Questionnaire;
