// import React, { useEffect, useState } from "react";
// import { FaChevronRight, FaChevronUp } from "react-icons/fa";
// import { useParams } from "react-router-dom";
// import styled from "styled-components";
// import {
//   CategoryBox,
//   CategoryContainer,
//   PercentageText,
//   ProgressBarContainer,
//   ProgressBarFill,
//   SectorContainer,
// } from "./styledComponents";

// const StyledFaChevronRight = styled(FaChevronRight)`
//   position: absolute;
//   right: 5%;
//   top: 25%;
//   font-size: 30px;
//   color: #0ea5e9;
// `;

// const StyledFaChevronUp = styled(FaChevronUp)`
//   position: absolute;
//   right: 5%;
//   top: 25%;
//   font-size: 30px;
//   color: #f2f2f2;
// `;

// const ProgressBar = ({ percentage }) => (
//   <>
//     <PercentageText>{percentage}%</PercentageText>
//     <ProgressBarContainer>
//       <ProgressBarFill percentage={percentage} />
//     </ProgressBarContainer>
//   </>
// );

// function Sector({ sector, isOpen, onToggle }) {
//   return (
//     <div onClick={onToggle}>
//       <h2>{`Sector ${sector.Sector}`}</h2>
//       {isOpen &&
//         sector.categories.map((category) => (
//           <Category key={category.category_name} category={category} />
//         ))}
//     </div>
//   );
// }

// // function Category({ category }) {
// //   return (
// //     <CategoryBox>
// //       <p>{category.category_name}</p>
// //       <ProgressBar percentage={category.bar_perc} />
// //     </CategoryBox>
// //   );
// // }

// function Category({ category, index }) {
//   return (
//     <CategoryBox>
//       <div style={{ position: "relative" }}>
//         <div style={{ position: "absolute", top: 0, left: 0 }}>{index + 1}</div>
//         <p>{category.category_name}</p>
//         <ProgressBar percentage={category.bar_perc} />
//         <a href="#">Lorem Ipsum</a>
//       </div>
//     </CategoryBox>
//   );
// }

// // function ResultPage() {
// //   const [sectors, setSectors] = useState([]);
// //   const { attemptId } = useParams(); // get attemptId from the URL

// //   const toggleSector = (index) => {
// //     setSectors(
// //       sectors.map((sector, i) =>
// //         i === index
// //           ? { ...sector, isExpanded: !sector.isExpanded }
// //           : { ...sector, isExpanded: false }
// //       )
// //     );
// //   };

// //   useEffect(() => {
// //     const fetchResults = async () => {
// //       try {
// //         const response = await fetch(
// //           `/api/v1/get_results/?attempt_id=${attemptId}`
// //         );
// //         if (!response.ok) {
// //           throw new Error("HTTP status " + response.status);
// //         }
// //         const data = await response.json();

// //         setSectors(data);
// //       } catch (error) {
// //         console.error("Failed to fetch the data: ", error);
// //       }
// //     };
// //     fetchResults();
// //   }, [attemptId]);

// //   return (
// //     <>
// //       {sectors.map((sector, index) => (
// //         <div key={index}>
// //           <SectorContainer
// //             onClick={() => toggleSector(index)}
// //             isSelected={sector.isExpanded}
// //           >
// //             <h2>{`Sector ${sector.Sector}`}</h2>
// //             {sector.isExpanded ? (
// //               <StyledFaChevronUp />
// //             ) : (
// //               <StyledFaChevronRight />
// //             )}
// //           </SectorContainer>
// //           {sector.isExpanded && (
// //             <CategoryContainer>
// //               {sector.categories.map((category, i) => (
// //                 <Category key={i} category={category}>
// //                   <p>{category.category_name}</p>
// //                   <ProgressBar percentage={category.bar_perc} />
// //                 </Category>
// //               ))}
// //             </CategoryContainer>
// //           )}
// //         </div>
// //       ))}
// //     </>
// //   );
// // }

// function ResultPage() {
//   const [sectors, setSectors] = useState([]);
//   const { attemptId } = useParams(); // get attemptId from the URL

//   useEffect(() => {
//     const fetchResults = async () => {
//       try {
//         const response = await fetch(
//           `/api/v1/get_results/?attempt_id=${attemptId}`
//         );
//         if (!response.ok) {
//           throw new Error("HTTP status " + response.status);
//         }
//         const data = await response.json();

//         setSectors(data);
//       } catch (error) {
//         console.error("Failed to fetch the data: ", error);
//       }
//     };
//     fetchResults();
//   }, [attemptId]);

//   return (
//     <>
//       {sectors.map((sector, index) => (
//         <div key={index}>
//           <h2>{`Sector ${sector.Sector}`}</h2>
//           <CategoryContainer>
//             {sector.categories.map((category, i) => (
//               <Category key={i} index={i} category={category}>
//                 <p>{category.category_name}</p>
//                 <ProgressBar percentage={category.bar_perc} />
//               </Category>
//             ))}
//           </CategoryContainer>
//         </div>
//       ))}
//     </>
//   );
// }

// export default ResultPage;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import {
  CategoryBox,
  CategoryContainer,
  CategoryLinkBox,
  ContentContainer,
  LinkContainer,
  PercentageText,
  ProgressBarContainer,
  ProgressBarFill,
  ResultContainer,
  TitleText,
  TopText,
} from "./styledComponents";

const CategoryTitle = styled.a`
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 8px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  font-size: 1.5rem;
  line-height: 1.8rem;

  &:hover {
    color: #0ea5e9;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const CategoryLeft = styled.div`
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CategoryRight = styled.div`
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DetailsTable = styled.table`
  color: #cccccc9e;

  th {
    font-weight: 600;
    width: 120px;
  }

  td {
    font-weight: 400;
  }

  @media (max-width: 768px) {
    tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }
    td {
      margin-left: 1rem;
    }
  }
`;

const ProgressBar = ({ percentage }) => (
  <>
    <PercentageText>{percentage}%</PercentageText>
    <ProgressBarContainer>
      <ProgressBarFill percentage={percentage} />
    </ProgressBarContainer>
  </>
);

// function Category({ category, index }) {
//   return (
//     <CategoryBox>
//       <div style={{ position: "relative" }}>
//         <div style={{ position: "absolute", top: 0, left: 0 }}>{index + 1}</div>
//         <p>{category.category_name}</p>
//         <ProgressBar percentage={category.bar_perc} />
//         <a href="#">Lorem Ipsum</a>
//       </div>
//     </CategoryBox>
//   );
// }

function Category({ category, index, attemptId }) {
  return (
    <CategoryLinkBox>
      <CategoryBox>
        <CategoryLeft>
          <CategoryTitle
            href={category.vaucher_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {index + 1}. {category.vaucher_name}
          </CategoryTitle>
          <DetailsTable>
            <tbody>
              <tr>
                <th>Zanimanje:</th>
                <td>{category.category_name}</td>
              </tr>
              <tr>
                <th>Sektor:</th>
                <td>{category.sector_name}</td>
              </tr>
            </tbody>
          </DetailsTable>
        </CategoryLeft>

        <CategoryRight>
          <ProgressBar percentage={category.bar_perc} />
        </CategoryRight>
      </CategoryBox>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "1rem",
        }}
      >
        <LinkContainer>
          <a
            key={index}
            href={"/compare_interests/" + attemptId + "/" + category.vaucher_id}
            target="_blank"
            rel="noopener noreferrer"
          >
            Odgovara li mojim <b>interesima?</b>
          </a>
        </LinkContainer>
        <LinkContainer>
          <a
            key={index}
            href={"/compare_skill/" + attemptId + "/" + category.vaucher_id}
            target="_blank"
            rel="noopener noreferrer"
          >
            Odgovara li mojim <b>vještinama?</b>
          </a>
        </LinkContainer>
      </div>
    </CategoryLinkBox>
  );
}

function ResultPage() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { attemptId } = useParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const textParts = [
    {
      content: ["Hvala na ispunjavanju upitnika!"],
    },
    {
      content: [
        "Pred Vama se nalazi ",
        {
          text: "popis obrazovnih programa unutar sustava vaučera ",
          bold: true,
        },
        "koji bi Vam mogli odgovarati. Uz svaki ponuđeni obrazovni program postoji brojčana procjena o tome koliko je sukladan Vašim interesima i vještinama. Postotci slaganja imaju sljedeća značenja:",
      ],
    },
    {
      content: [
        {
          type: "list",
          items: [
            [
              { text: "90% do 100%", bold: true },
              " program Vam ",
              { text: "odlično odgovara", bold: true },
            ],
            [
              { text: "75% do 89%", bold: true },
              " program Vam ",
              { text: "dobro odgovara", bold: true },
            ],
            [
              { text: "60% do 74%", bold: true },
              " program Vam ",
              { text: "osrednje odgovara", bold: true },
            ],
            [
              { text: "Ispod 60%", bold: true },
              " program Vam ",
              { text: "uglavnom ne odgovara", bold: true },
            ],
          ],
        },
      ],
    },
    {
      content: [
        "Obrazovni programi koji imaju sukladnost veću od 75% Vam prilično odgovaraju, dok Vam oni sa sukladnosti većom od 90% odlično pristaju, odnosno odlično odgovaraju Vašim interesima i vještinama. Vjerojatno ćete lakše i uspješnije završiti obrazovanje koje Vam je sukladno i vjerojatno ćete biti zadovoljniji i uspješniji u zanimanju koje Vam visoko odgovara.",
      ],
    },
    {
      content: [
        {
          text: "Proučite obrazovne programe koji su Vam prikladni i razmislite o njima",
          bold: true,
        },
        ". Jesu li to dobre opcije za Vas? Ukoliko nijedan od ponuđenih obrazovnih programa nema visoku sukladnost s vašim interesima i vještinama možete pričekati i ispuniti ovaj upitnik ponovno kroz nekoliko tjedana ili mjeseci jer se sustav vaučera stalno nadopunjuje novim obrazovnim programima koji će Vam možda više odgovarati.",
      ],
    },
    {
      content: [
        "Nadalje, svaki obrazovni program je povezan s nekim zanimanjem i sektorom u svijetu rada. ",
        {
          text: "Jesu li Vam ta zanimanja i područja rada bliska i interesiraju li Vas? ",
          bold: true,
        },
        "Radite li ili ste radili u sličnom zanimanju ili području? Ako su Vaši odgovori na ova pitanja potvrdni, vjerojatno je da bi Vam ponuđeni obrazovni programi odgovarali.",
      ],
    },
    {
      content: [
        "Kada kliknete na naziv nekog obrazovnog programa bit ćete prebačeni na stranice sustava ",
        {
          type: "link",
          text: "vauceri.hzz.hr",
          url: "https://vauceri.hzz.hr",
        },
        " gdje ćete moći dobiti ",
        { text: "dodatne informacije ", bold: true },
        "o tom obrazovnom programu (npr.njegovo trajanje, gdje se izvodi, koji su uvjeti upisa, kako dobiti vaučer).",
      ],
    },
    {
      content: [
        "Ako želite opći profesionalni savjet možete vidjeti ",
        {
          text: "povratnu informaciju o svojim interesima i vještinama ",
          bold: true,
        },
        "klikom na linkove ",
        {
          type: "link",
          text: "Moji interesi",
          url: "/interests/" + attemptId,
        },
        " i ",
        {
          type: "link",
          text: "Moje vještine",
          url: "/skills/" + attemptId,
        },
        ". Također, možete usporediti svoje interese i vještine sa zahtjevima pojedinog obrazovnog programa, odnosno detaljnije provjeriti koliko vam odgovara, klikom na ponuđene opcije s desne strane programa.",
      ],
    },
    {
      content: [
        "Ako Vam je potrebno dodatno pojašnjenje ili savjet oko donošenja odluke o odabiru odgovarajućeg obrazovnog programa, možete ",
        {
          type: "link",
          text: "zatražiti pomoć savjetnika",
          url: "https://vauceri.hzz.hr/katalog-vjestina-hzz-vauceri/hzz-savjetnici/",
        },
        " za profesionalno usmjeravanje HZZ-a.",
      ],
    },
    {
      content: [
        "Ako želite ponovno ispuniti upitnik, kliknite ",
        {
          type: "link",
          text: "ovdje",
          url: "/",
        },
        ".",
      ],
    },
  ];
  // const paragraphs = textParts.map((part, index) => (
  //   <p key={index}>
  //     {part.content.map((content, i) => {
  //       if (typeof content === "string") {
  //         return <span key={i}>{content}</span>;
  //       } else if (content.type === "link") {
  //         return (
  //           <a
  //             key={i}
  //             href={content.url}
  //             target="_blank"
  //             rel="noopener noreferrer"
  //           >
  //             {content.text}
  //           </a>
  //         );
  //       }
  //       return null;
  //     })}
  //   </p>
  // ));

  function renderText(content) {
    if (typeof content === "string") {
      return <span>{content}</span>;
    } else if (content.bold) {
      return <b>{content.text}</b>;
    } else {
      return <span>{content.text}</span>;
    }
  }

  const paragraphs = textParts.map((part, index) => (
    <p key={index}>
      {part.content.map((content, i) => {
        if (typeof content === "string" || (content && content.bold)) {
          return renderText(content);
        } else if (content.type === "link") {
          return (
            <a
              key={i}
              href={content.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {content.text}
            </a>
          );
        } else if (content.type === "list") {
          return (
            <ul key={i}>
              {content.items.map((listItem, j) => (
                <li key={j}>{listItem.map((item, k) => renderText(item))}</li>
              ))}
            </ul>
          );
        }
        return null;
      })}
    </p>
  ));

  // const paragraphs = textParts.map((part, index) => (
  // 	<p key={index}>
  // 		{part.content.map((content, i) => {
  // 			if (content.type === "text") {
  // 				return content.isBold ? (
  // 					<strong key={i}>{content.text}</strong>
  // 				) : (
  // 					<span key={i}>{content.text}</span>
  // 				);
  // 			} else if (content.type === "link") {
  // 				return (
  // 					<a
  // 						key={i}
  // 						href={content.url}
  // 						target="_blank"
  // 						rel="noopener noreferrer"
  // 					>
  // 						{content.text}
  // 					</a>
  // 				);
  // 			} else if (content.type === "list") {
  // 				return (
  // 					<ul key={i}>
  // 						{content.items.map((item, j) => (
  // 							<li key={j}>{item}</li>
  // 						))}
  // 					</ul>
  // 				);
  // 			}
  // 			return null;
  // 		})}
  // 	</p>
  // ));

  function timeout(ms) {
    return new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Request timed out")), ms)
    );
  }

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await Promise.race([
          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get_results/?attempt_uuid=${attemptId}`),
          timeout(30000), // 30 seconds timeout
        ]);
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
        const data = await response.json();

        setCategories(data);
        setLoading(false);
      } catch (error) {
        if (error.message === "Request timed out") {
          // Set the error message for display
          setErrorMessage(
            "Nismo uspjeli dohvatiti rezultate. Pokušajte osvježiti stranicu."
          );
        } else {
          setErrorMessage(
            "Nismo uspjeli dohvatiti rezultate. Pokušajte osvježiti stranicu."
          );
        }
        console.error("Failed to fetch the data: ", error);
      }
    };
    fetchResults();
  }, [attemptId]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {errorMessage ? (
          <TitleText>{errorMessage}</TitleText>
        ) : (
          <>
            <TitleText>
              Dohvaćanje rezultata. Ovo može potrajati nekoliko trenutaka.
            </TitleText>
            <ClipLoader color="#0ea5e9" size={150} />
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      <ContentContainer style={{ marginBottom: 40 }}>
        <TopText>{paragraphs}</TopText>
      </ContentContainer>

      <ResultContainer>
        <CategoryContainer>
          {categories.map((category, index) => (
            <Category
              key={index}
              index={index}
              category={category}
              attemptId={attemptId}
            />
          ))}
        </CategoryContainer>
      </ResultContainer>
    </div>
  );
}

export default ResultPage;
