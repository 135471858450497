import React from "react";
import styled from "styled-components";
import Footer from "./Footer";
import Header from "./Header";

const MainContent = styled.div`
  flex-grow: 1;
  max-width: 1080px;
  color: #ccc;
  padding: 10px;
  margin-bottom: 40px;
`;

const HeaderWrapper = styled.div`
  flex: none; // This will make sure the header doesn't grow or shrink
  height: 30vh; // Adjust this to fit your needs
  width: 100%;
`;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
`;

const Layout = ({ children }) => (
  <LayoutContainer>
    <HeaderWrapper>
      <Header />
    </HeaderWrapper>
    <MainContent>{children}</MainContent>
    <Footer />
  </LayoutContainer>
);

export default Layout;
