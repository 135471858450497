import React, { useEffect, useState } from "react";
import OccupationQuestionnaire from "./OccupationQuestionaire";
import PersonalQuestions from "./PersonalQuestions";
import Questionnaire from "./Questionnaire";
import Start from "./Start";

function CompleteQuestionnaire() {
  const [step, setStep] = useState("start");
  const [attemptId, setAttemptId] = useState("");

  useEffect(() => {
    const fetchAttemptId = async () => {
      const userId = 0; // This might change based on your app's structure

      // Check for saved attemptId in localStorage
      const savedAttemptId = localStorage.getItem("attempt_id");
      if (savedAttemptId) {
        setAttemptId(savedAttemptId);
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/generate_attempt/?user_id=${userId}`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
          }
        );
        const data = await response.json();
        const newAttemptId = data.attempt_uuid;
        setAttemptId(newAttemptId);

        // Save the new attempt_id to localStorage
        localStorage.setItem("attempt_id", newAttemptId);
      }
    };

    fetchAttemptId();
  }, []);
  return (
    <div>
      {step === "start" && (
        <Start
          attemptId={attemptId}
          onNext={() => setStep("personal_questions")}
        />
      )}
      {step === "personal_questions" && (
        <PersonalQuestions
          attemptId={attemptId}
          onNext={() => setStep("occupation_questions")}
          onBack={() => setStep("start")}
        />
      )}
      {step === "occupation_questions" && (
        <OccupationQuestionnaire
          attemptId={attemptId}
          onNext={() => setStep("questions")}
          onBack={() => setStep("personal_questions")}
        />
      )}
      {step === "questions" && (
        <Questionnaire
          attemptId={attemptId}
          onBack={() => setStep("occupation_questions")}
        />
      )}
    </div>
  );
}

export default CompleteQuestionnaire;
