import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { useScroll } from "../hooks";
import ProgressBar from "./ProgressBar";
import Questions from "./Questions";
import AllSameAnswersModal from "./allSameAnswers";
import {
  ActionButton,
  ButtonsWrapper,
  ContentContainer,
  OptionLabelContainer,
  OptionLabelText,
  QuestionPage,
  TopText,
} from "./styledComponents";

const Blank = styled.div``;

const OptionLabels = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
`;

const QuestionsContainer = styled(ContentContainer)`
  @media (max-width: 768px) {
    padding: 0;
    background: none;
    border: none;
  }
`;

const OccupationQuestionnaire = ({ attemptId, onNext, onBack }) => {
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(0);
  const [answers, setAnswers] = useState([]);
  // const [highestPageVisited, setHighestPageVisited] = useState(0);
  const navigate = useNavigate();
  const [section, setSection] = useState(1); // State to handle section
  const [animationKey, setAnimationKey] = useState(Date.now());
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const scroller = useScroll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const questionsPerPage = 18;

  const sectionData = {
    1: {
      labels: [
        "Uopće mi se ne sviđa",
        "Ne sviđa mi se",
        "Niti mi se sviđa, niti mi se ne sviđa",
        "Sviđa mi se",
        "Jako mi se sviđa",
      ],
      topText: {
        content: [
          "Pred Vama se nalazi popis različitih zanimanja. Pročitajte ova zanimanja i za svako ocijenite koliko Vam se sviđa tako što ćete označiti jedan broj na skali od 1 do 5, čija su značenja opisana.",
          {
            type: "list",
            items: [
              ["1 - Uopće mi se ne sviđa"],
              ["2 - Ne sviđa mi se"],
              ["3 - Niti mi se sviđa, niti mi se ne sviđa"],
              ["4 - Sviđa mi se"],
              ["5 - Jako mi se sviđa"],
            ],
          },
          "Odgovarajte iskreno i slijedite svoje interese. Koliko Vam se sviđaju sljedeća zanimanja, odnosno biste li se voljeli njima baviti?",
        ],
      },
    },
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/occupation_questions`); // replace with your back-end endpoint
      const questions = await response.json();

      setQuestions(questions);

      const answersResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/get_occupation_answers/?attempt_uuid=${attemptId}`
      );
      const fetchedAnswers = await answersResponse.json();

      const defaultAnswers = Array.from({ length: questions.length }, () => ({
        answered: false,
        color: "transparent",
      }));

      // Update the default answers array based on the fetched answers
      for (let fetchedAnswer of fetchedAnswers) {
        const questionIndex = questions.findIndex(
          (q) => q.id === fetchedAnswer.question_id
        );
        if (questionIndex !== -1) {
          defaultAnswers[questionIndex] = {
            ...defaultAnswers[questionIndex],
            answer: fetchedAnswer.answer,
            answered: true,
          };
        }
      }

      setAnswers(defaultAnswers);
    };

    //   setAnswers(
    //     Array.from({ length: questions.length }, () => ({
    //       // answer: Math.ceil(Math.random() * 5), // FOR TESTING
    //       answered: false,
    //       color: "transparent",
    //     }))
    //   );
    // };

    fetchQuestions();
  }, []);

  const handleAnswer = (questionIndex, answer) => {
    const newAnswers = answers.map((answer) => ({
      ...answer,
      color: "transparent",
    }));
    newAnswers[questionIndex] = {
      ...newAnswers[questionIndex],
      answer: answer,
      answered: true,
    };
    setAnswers(newAnswers);
  };

  // const WarningModal = ({ isOpen, onConfirm }) => {
  //   return (
  //     <div style={{ display: isOpen ? 'block' : 'none' }}>
  //       <p>All questions have the same answers! Are you sure about your selections?</p>
  //       <button onClick={onConfirm}>OK</button>
  //     </div>
  //   );
  // };

  const handleNext = async () => {
    // const firstQuestionOnPage = page * questionsPerPage;
    // const lastQuestionOnPage = firstQuestionOnPage + questionsPerPage;
    let newAnswers = [...answers];

    let allAnswered = true;

    for (let i = 0; i < questions.length; i++) {
      if (!newAnswers[i] || newAnswers[i].answer === undefined) {
        newAnswers[i] = { ...newAnswers[i], color: "red" };
        allAnswered = false;
      }
    }

    if (!allAnswered) {
      setAnswers(newAnswers);
      setAnimationKey(Date.now()); // Reset the animation key
      return;
    }

    const formattedAnswers = answers.map((answer, index) => ({
      question_id: questions[index].id,
      answer: answer.answer,
    }));

    // POST the data to the server
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/submit_occupation_answers/?attempt_uuid=${attemptId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(formattedAnswers),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
      return; // Exit the function in case of error
    }

    //   if (allSame) {
    //     // Open the warning modal if all answers are the same
    //     setIsWarningModalOpen(true);
    //     return; // Return early to avoid any subsequent logic
    //   }
    // }

    // for (let i = firstQuestionOnPage; i < lastQuestionOnPage; i++) {
    //   if (!newAnswers[i] || newAnswers[i].answer === undefined) {
    //     newAnswers[i] = { ...newAnswers[i], color: "red" };
    //     allAnswered = false;
    //   }
    // }

    // if (!allAnswered) {
    //   setAnswers(newAnswers);
    //   setAnimationKey(Date.now()); // Reset the animation key
    //   return;
    // }
    scroller.scrollTo("#page-anchor", { block: "start" });
    onNext();
  };

  const handleBack = () => {
    onBack();
  };

  useEffect(() => {
    if (answers.find((answer) => answer.color === "red")) {
      scroller.scrollTo(".unanswered", { block: "center" });
    }
    // eslint-disable-next-line
  }, [answers]);

  function renderText(content) {
    if (typeof content === "string") {
      return <span>{content}</span>;
    } else if (content.type === "list") {
      return (
        <ul style={{ listStyleType: "none" }}>
          {content.items.map((listItem, index) => (
            <li key={index}>{listItem.map((item, i) => renderText(item))}</li>
          ))}
        </ul>
      );
    } else if (content.bold) {
      return <b>{content.text}</b>;
    } else {
      return <span>{content.text}</span>;
    }
  }

  return (
    <QuestionPage id="page-anchor">
      {/* <TitleText>TEST</TitleText> */}
      <ContentContainer>
        <TopText>
          {" "}
          {sectionData[section].topText.content.map((content, index) => (
            <div key={index}>{renderText(content)}</div>
          ))}{" "}
        </TopText>
      </ContentContainer>
      <QuestionsContainer>
        <OptionLabelContainer>
          <Blank />
          <OptionLabels>
            {sectionData[section].labels.map((label, index) => (
              <OptionLabelText key={index}>{label}</OptionLabelText>
            ))}
          </OptionLabels>
        </OptionLabelContainer>
        <Questions
          questions={questions}
          page={page}
          // questionsPerPage={questionsPerPage}
          startIndex={0}
          endIndex={questions.length}
          answers={answers}
          handleAnswer={handleAnswer}
          animationKey={animationKey} // pass the animation key down
        />
        <ButtonsWrapper>
          <ActionButton onClick={handleBack}>Natrag</ActionButton>
          <ActionButton onClick={handleNext}>Sljedeći</ActionButton>
        </ButtonsWrapper>
        <ProgressBar currentStep={3} page={page} />
        <AllSameAnswersModal
          isOpen={isWarningModalOpen}
          onConfirm={() => setIsWarningModalOpen(false)} // You can add any additional logic in this callback if needed
        />
      </QuestionsContainer>
    </QuestionPage>
  );
};

export default OccupationQuestionnaire;
