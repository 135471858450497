import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Bar, Radar } from "react-chartjs-2";
// import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";
import {
  BoxPlotContainer,
  ContentContainer,
  InterestPage,
  TitleText,
  TopText,
} from "./styledComponents";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const firstTextParts = [
  {
    content: [
      "Na donjem prikazu možete vidjeti koliko Vaše vještine odgovaraju ovom obrazovanom programu - ",
      {
        type: "voucher_name",
      },
      ".",
    ],
  },
  {
    content: [
      "Usporedite Vaše vještine s vještinama koje zahtijeva obrazovni program. Pogledajte:",
    ],
  },
  {
    content: [
      {
        type: "list",
        items: [
          "Jesu li Vaše vještine usklađene sa zahtjevima programa?",
          "Postoje li vještine koje nemate u dovoljnoj mjeri razvijene?",
          "Imate li neke vještine više razvijene nego što obrazovni program zahtijeva?",
        ],
      },
    ],
  },
  {
    content: ["Što u takvim situacijama?"],
  },
  {
    content: [
      "Ako su Vaše vještine usklađene sa zahtjevima programa: To je program koji odgovara Vašim trenutnim vještinama, moći ćete ga pratiti i savladati, a ukoliko Vas sadržaj programa zanima, to je dobar izbor za Vas.",
    ],
  },
  {
    content: [
      "Ako obrazovni program zahtijeva neke vještine koje nemate dovoljno razvijene: Ukoliko razlika nije velika, a želite razviti te vještine kroz obrazovni program, to bi mogao biti dobar izbor za Vas. Ukoliko je razlika velika (dvadeset ili više bodova) moguće je da ćete imati poteškoća pratiti obrazovni program i da ga možda nećete uspjeti savladati.",
    ],
  },
  {
    content: [
      "Ako su Vaše vještine razvijenije od onih koje obrazovni program zahtijeva: Ukoliko je razlika mala, i ako Vas program zanima, to bi mogao biti dobar izbor za Vas jer ćete taj program moći lako savladati. No ako je razlika velika (dvadeset ili više bodova) pitanje je hoćete li njime na najbolji način razviti svoje potencijale.",
    ],
  },
  {
    content: [
      "Također, imajte na umu da se radi o Vašoj samoprocjeni vještina, a ne o objektivnoj mjeri koliko ste u nečemu dobri.",
    ],
  },
  {
    content: [
      "Da biste bolje razumjeli što pojedina vještina obuhvaća, stavite kursor na stupac koji predstavlja pojedinu vještinu i pročitajte njezin opis.",
    ],
  },
];

const secondTextParts = [
  {
    id: "Vještine učenja sadržaja",
    content: [
      "Vještine učenja sadržaja potrebne su za rad,",
      "usvajanje znanja i stjecanje specifičnih vještina.",
      "Podrazumijevaju dobro razumijevanje pročitanog,",
      "aktivno slušanje, pisanje, govorno izražavanje te",
      "matematičke i znanstvene vještine.",
    ],
  },
  {
    id: "Vještine koje potpomažu proces učenja",
    content: [
      "Vještine koje potpomažu proces učenja jesu postupci",
      "koji pridonose bržem usvajanju znanja i specifičnih vještina.",
      "To su vještine kritičkog mišljenja, aktivnog učenja, dobrih",
      "strategija učenja i nadgledanja vlastitog procesa učenja i rada.",
    ],
  },
  {
    id: "Socijalne vještine",
    content: [
      "Socijalne vještine podrazumijevaju učinkovit rad s",
      "ljudima u cilju postizanja postavljenih ciljeva. Takve",
      "vještine uključuju razumijevanje drugih, usklađenost s",
      "drugima, uvjeravanje, pregovaranje, poučavanje i pružanje pomoći.",
    ],
  },
  {
    id: "Vještine rješavanja složenih problema",
    content: [
      "Vještine rješavanja složenih problema potrebne su kako",
      "bi se prepoznali i riješili novi, nejasno definirani problemi",
      "u složenim stvarnim radnim situacijama. Uključuju prepoznavanje",
      "problema, analizu potrebnih informacija te razvoj i",
      "implementaciju novih rješenja.",
    ],
  },
  {
    id: "Tehničke vještine",
    content: [
      "Tehničke vještine su potrebne da se projektiraju,",
      "uspostave, koriste i poprave strojevi ili tehnološki sustavi.",
      "Podrazumijevaju analizu potreba, razvoj tehnologije,",
      "odabir i ugradnju opreme, programiranje, praćenje rada i",
      "kontrolu sustava, održavanje opreme i uklanjanje kvarova.",
    ],
  },
  {
    id: "Vještine upravljanja sustavima",
    content: [
      "Vještine upravljanja sustavima su potrebne da se razumiju,",
      "nadgledaju i unaprijede sociotehnološki sustavi.",
      "Uključuju prosuđivanje i donošenje odluka te analizu i",
      "evaluaciju sustava.",
    ],
  },
  {
    id: "Vještine upravljanja resursima",
    content: [
      "Vještine upravljanja resursima su potrebne za učinkovitu",
      "raspodjelu i korištenje resursa u radu. Podrazumijevaju",
      "upravljanje vremenom te financijskim, materijalnim",
      "ili ljudskim resursima.",
    ],
  },
];

function CompareSkill() {
  const { attemptId } = useParams();
  const { voucherId } = useParams();
  const [chartData, setChartData] = useState(null);
  const [voucherName, setVoucherName] = useState();

  const firstParagraphs = firstTextParts.map((part, index) => (
    <p key={index}>
      {part.content.map((content, i) => {
        if (typeof content === "string") {
          return <span key={i}>{content}</span>;
        } else if (content.type === "link") {
          return (
            <a
              key={i}
              href={content.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {content.text}
            </a>
          );
        } else if (content.type === "list") {
          return (
            <ul key={i}>
              {content.items.map((item, j) => (
                <li key={j}>{item}</li>
              ))}
            </ul>
          );
        } else if (content.type === "voucher_name") {
          return <span key={i}>{voucherName}</span>;
        }
        return null;
      })}
    </p>
  ));

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/compare_skills/?attempt_uuid=${attemptId}&voucher_id=${voucherId}`
        );
        const responseJSON = await response.json();
        let { labels, dataAttempt, dataOccupation, voucherName } = responseJSON;

        // Round the dataAttempt and dataOccupation values
        dataAttempt = dataAttempt.map((value) => Math.round(value));
        dataOccupation = dataOccupation.map((value) => Math.round(value));
        // console.log(data.slice(0, 2));
        // const fundamentalData = data.map((item, index) => {
        //   return index < 2 ? item : 0;
        // });
        // const specificData = data.map((item, index) => {
        //   return index < 2 ? 0 : item;
        // });
        const formattedData = {
          labels: labels,
          datasets: [
            {
              label: "Moje vještine",
              data: dataAttempt,
              backgroundColor: "rgba(14, 165, 233,0.2)",
              borderColor: "rgba(14, 165, 233,1)",
              borderWidth: 2,
              barThickness: 20,
              hoverBackgroundColor: "rgba(14, 165, 233,0.4)",
              hoverBorderColor: "rgba(14, 165, 233,1)",
            },
            {
              label: "Zahtjevi obrazovnog programa",
              data: dataOccupation,
              backgroundColor: "rgba(226, 122, 18,0.2)",
              borderColor: "rgba(226, 122, 18,1)",
              borderWidth: 3,
              barThickness: 30,
              // barPercentage: 1.5, // Overlap bars by adjusting this property
              // categoryPercentage: 0.5, // Adjust the space between bars
              hoverBackgroundColor: "rgba(226, 122, 18,0.4)",
              hoverBorderColor: "rgba(226, 122, 18,1)",
            },
          ],
        };
        setVoucherName(voucherName);
        setChartData(formattedData);
      } catch (error) {
        console.error("Error fetching interests:", error);
      }
    }

    fetchData();
  }, [attemptId]);

  /*
  const options = {
    series: [
      {
        name: "Radar Series 1",
        data: [45, 52, 38, 24, 33, 10],
      },
      {
        name: "Radar Series 2",
        data: [26, 21, 20, 6, 8, 15],
      },
    ],
    labels: ["April", "May", "June", "July", "August", "September"],
  };
  */
  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    scales: {
      y: {
        stacked: true,
      },
      x: {
        min: 0,
        max: 100,
      },
    },
    // tooltips: {
    //   mode: "label",
    // },
    plugins: {
      tooltip: {
        mode: "y",
        // intersect: false,
        backgroundColor: "#202124",
        padding: 15,
        titleColor: "#ccc",
        bodyColor: "#ccc",
        footerColor: "#ccc",
        titleFont: {
          size: 18,
        },
        bodyFont: {
          size: 16,
        },
        footerFont: {
          size: 14,
        },
        callbacks: {
          footer: (items) => {
            const { label } = items[0];

            if (!label) return null;

            const { content } = secondTextParts.find(
              (item) => item.id === label
            );

            return content;
          },
        },
      },
      legend: {
        labels: {
          color: "#ccc",
          font: {
            size: 18,
          },
        },
      },
    },
  };
  return (
    <div>
      <TitleText>Vaše vještine i obrazovni program: {voucherName}</TitleText>
      <ContentContainer>
        <TopText>{firstParagraphs}</TopText>
      </ContentContainer>
      <InterestPage>
        <ContentContainer style={{ display: "flex", justifyContent: "center" }}>
          <BoxPlotContainer>
            {chartData ? (
              // <Radar
              //   data={chartData}
              //   options={{
              //     scales: {
              //       r: {
              //         min: 0,
              //         max: 100,
              //         grid: {
              //           color: "#ccc",
              //         },
              //         pointLabels: {
              //           color: "#fff",
              //           font: {
              //             size: 18,
              //           },
              //         },
              //       },
              //     },
              //     plugins: {
              //       tooltip: {
              //         backgroundColor: "#202124",
              //         padding: 15,
              //         titleColor: "#ccc",
              //         bodyColor: "#ccc",
              //         footerColor: "#ccc",
              //         titleFont: {
              //           size: 18,
              //         },
              //         bodyFont: {
              //           size: 16,
              //         },
              //         footerFont: {
              //           size: 14,
              //         },
              //         callbacks: {
              //           footer: (items) => {
              //             const { label } = items[0];

              //             if (!label) return null;

              //             const { content } = secondTextParts.find(
              //               (item) => item.id === label
              //             );

              //             return content;
              //           },
              //         },
              //       },
              //       legend: {
              //         labels: {
              //           color: "#ccc",
              //           font: {
              //             size: 18,
              //           },
              //         },
              //       },
              //     },
              //   }}
              // />
              <Bar options={options} data={chartData} />
            ) : (
              <p>Loading...</p>
            )}
          </BoxPlotContainer>
        </ContentContainer>
      </InterestPage>
    </div>
  );
}

export default CompareSkill;
