import React from "react";
import { styled } from "styled-components";
import {
  ContentContainer,
  Line,
  OptionsContainer,
  QuestionContainer,
  QuestionText,
  RadioButton,
} from "./styledComponents";

const QuestionGroupContainer = styled(ContentContainer)`
  padding: 0;
`;

const Questions = ({
  questions,
  // page,
  // questionsPerPage,
  startIndex,
  endIndex,
  answers,
  handleAnswer,
  animationKey,
}) => {
  return (
    <>
      <QuestionGroupContainer>
        {questions.slice(startIndex, endIndex).map((question, index) => {
          const questionIndex = startIndex + index;
          const isUnanswered =
            answers[questionIndex] && answers[questionIndex].color === "red";
          return (
            <QuestionContainer
              className={isUnanswered ? "unanswered" : ""}
              key={question.id + -"" + animationKey}
              isUnanswered={isUnanswered}
            >
              <QuestionText>{question.text}</QuestionText>
              <OptionsContainer>
                {[1, 2, 3, 4, 5].map((option) => (
                  <label key={option}>
                    <RadioButton
                      name={questionIndex.toString()}
                      value={option}
                      checked={
                        answers[questionIndex] &&
                        answers[questionIndex].answer === option
                      }
                      onChange={() => handleAnswer(questionIndex, option)}
                    />
                  </label>
                ))}
              </OptionsContainer>
              <Line />
            </QuestionContainer>
          );
        })}
      </QuestionGroupContainer>
    </>
  );
};

export default Questions;
