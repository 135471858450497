import styled, { css, keyframes } from "styled-components";

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: auto;
  padding: 20px;
  background: #2a2b2f;
  border: 1px solid #454649;
  border-radius: 12px;
  margin-bottom: 20px; // to add some space between two containers

  @media (max-width: 768px) {
    padding: 10px;
  }
`;
export const QuestionPage = styled.div`
  padding-bottom: 2rem;
`;

const fadeQuestion = keyframes`
  0% { border-color: #BB342F; }
  100% { border-color: transparent; }
`;

export const QuestionContainer = styled.div`
  display: grid;
  padding: 15px 20px 0;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: center;
  border: 1px solid;
  border-color: ${(props) => (props.isUnanswered ? "#BB342F" : "transparent")};
  animation: ${(props) =>
    props.isUnanswered
      ? css`
          ${fadeQuestion} 1s linear 3.3s forwards
        `
      : "none"};
  &:hover {
    background-color: #36373c;
  }
  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 10px 0;
  }
`;

const fadeContainer = keyframes`
  0% { border-color: #BB342F; }
  100% { border-color: #454649; }
`;

export const PersonalQuestionContainer = styled.div`
  box-sizing: border-box;
  width: auto;
  padding: 20px;
  background: #2a2b2f;
  border-radius: 12px;
  margin-bottom: 20px; // to add some space between two containers
  border: 1px solid;
  border-color: ${(props) => (props.isUnanswered ? "#BB342F" : "#454649")};
  animation: ${(props) =>
    props.isUnanswered
      ? css`
          ${fadeContainer} 1s linear 10.3s forwards
        `
      : "none"};
`;

export const RadarContainer = styled.div`
  box-sizing: border-box;
  width: 90%;
  overflow: visible;
  aspect-ratio: 1 / 1;
  max-width: 600px; // This ensures that the container doesn't overflow if the viewport is too small. It considers 20px padding on both sides.
  border-radius: 12px;
`;

export const BoxPlotContainer = styled.div`
  box-sizing: border-box;
  width: 90%;
  overflow: visible;
  aspect-ratio: 2 / 1;
  max-width: 1200px; // This ensures that the container doesn't overflow if the viewport is too small. It considers 20px padding on both sides.
  border-radius: 12px;
`;

export const InterestPage = styled.div``;

export const ResultContainer = styled.div`
  margin-bottom: 20px; // to add some space between two containers
`;

export const TopText = styled.div`
  text-align: left;
  font-size: 1rem; // Or whatever size you prefer
  margin: 16px 0;
  line-height: 1.4;
`;

export const TitleText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 2rem;
`;

export const Line = styled.hr`
  border: 1px solid #484848;
  width: 100%; // This will make the line stretch across the entire container
  border-color: #616164;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 20px 0;
`;

export const ActionButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  gap: 10px;

  /* Styles from Figma */
  background: #0ea5e9;
  border-radius: 25px;
  border: 2px solid;
  border-color: #0ea5e9;
  &:focus {
    outline: none;
  }

  /* Text Styles */
  font-weight: 700;
  font-size: 1.4em;
  line-height: 15px;
  text-align: center;
  color: #f2f2f2;

  /* Hover Effects */
  transition: 0.3s;
  &:hover {
    background: #0c9fd6;
    cursor: pointer;
  }

  /* Click Effects */
  &:active {
    background: #0a89c1;
  }
	&:disabled {
    background-color: #aaa;
    border-color: #aaa;
    cursor: not-allowed;
`;

export const SectorContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 1514px;
  height: 80px;
  margin: auto;
  margin-bottom: 20px; // add some space between sectors

  /* Apply different styles based on whether the sector is selected */
  background: ${(props) => (props.isSelected ? "#454649" : "#2a2b2f")};
  border: 3px solid ${(props) => (props.isSelected ? "#454649" : "#2a2b2f")};

  /* Hover effect */
  &:hover {
    border-color: #0ea5e9; /* Change this to the color you want on hover */
  }

  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;

  h2 {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #ffffff;
  }

  img {
    position: absolute;
    right: 10px; // adjust this as needed
  }
`;

export const ProgressBarContainer = styled.div`
  background-color: #f3f3f3;
  border-radius: 50px;
  height: 10px;
  width: 100%;
`;

export const ProgressBarFill = styled.div`
  background-color: #0ea5e9;
  border-radius: 50px;
  height: 10px;
  width: ${(props) => props.percentage}%;
`;

export const CategoryContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between; // Distribute items evenly in the container
  flex-wrap: wrap; // Allow items to wrap to the next line
  animation: ${slideDown} 500ms ease-in-out forwards;
`;

export const CategoryLinkBox = styled.div`
  min-height: 200px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: auto;
  display: flex;
  // flex-direction: row;
  gap: 20px;
  justify-content: space-between; // Distribute items evenly in the container
  // flex-wrap: wrap; // Allow items to wrap to the next line
  // animation: ${slideDown} 500ms ease-in-out forwards;
`;

export const LinkContainer = styled.div`
  box-sizing: border-box; // add this line
  flex: 0 1 5%; /* flex-grow, flex-shrink, flex-basis */
  padding: 14px;
  box-sizing: border-box;
  background: #2a2b2f;
  border: 1px solid #454649;
  border-radius: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  flex-grow: 1;

  align-items: center; /* centers children vertically */
  justify-content: center; /* centers children horizontally */
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 10px;
  }
`;

export const CategoryBox = styled.div`
  box-sizing: border-box; // add this line
  flex: 0 1 110%; /* flex-grow, flex-shrink, flex-basis */
  // margin: 1rem;
  padding: 20px;
  box-sizing: border-box;
  background: #2a2b2f;
  border: 1px solid #454649;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 10px;
  }
`;

export const PercentageText = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: center;
  color: #0ea5e9;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 0;
  }
`;

export const QuestionText = styled.div`
  grid-column: 1 / 2;
  font-size: 1.5em;
`;

export const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-items: center;
  grid-column: 2 / 2;
  width: 100%;

  @media (max-width: 768px) {
    // display: flex;
  }
`;

export const OptionLabel = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
`;

export const OptionLabelText = styled.span`
  font-size: 1.1rem;
  color: #0ea5e9;
  text-align: center;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const StyledInputBox = styled.input`
  appearance: none;
  width: 18px;
  height: 18px;
  background: none;
  outline: none;
  box-shadow: 0 0 0 2px #adadad;
  cursor: pointer; // Pointer to indicate it's clickable
  margin: 5px;
  transition: transform 0.1s ease-out;

  &:hover {
    transform: scale(1.1);
    background: #adadad70;
  }

  &:checked {
    background: #0ea5e9;
    box-shadow: 0 0 0 2px #0ea5e9;
  }

  &:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    margin: 4px;
  }

  &:checked:before {
    background: #a0e2ff;
  }
`;

export const Checkbox = styled(StyledInputBox).attrs({ type: "checkbox" })`
  border-radius: 4px;
  &:before {
    border-radius: 2px;
  }
`;

export const RadioButton = styled(StyledInputBox).attrs({ type: "radio" })`
  border-radius: 50%;
  &:before {
    border-radius: 50%;
  }
`;

export const VerticalLabel = styled.label`
  grid-column: 1 / 2;
  font-size: 1.5em;
  cursor: pointer; // Again, to indicate the text is clickable and related to the checkbox
  margin-left: 10px; // A little space between the checkbox and label
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center; // This will vertically center the checkbox and the label
  justify-content: flex-start;
`;

export const TextInput = styled.input.attrs({
  placeholder: "Ovdje unesite tekst",
})`
  font-size: 1.5em;
  padding: 8px 12px;
  border: 2px solid #454649;
  border-radius: 8px;
  background-color: #2a2b2f;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px; // Adds space between the question and the text input
  &::placeholder {
    color: #adadad; // A lighter color for placeholder, adjust as needed
    opacity: 1; // Firefox default is 0.54
  }

  // Styling when the input is focused
  &:focus {
    border-color: #0ea5e9; // Highlighting the border when focused, adjust color as needed
  }
`;

// export const TextInput = styled.input.attrs({
//   placeholder: "Placeholder",
// })`
//   grid-column: 1 / 2;
//   font-size: 1.5em;
//   padding: 10px; // Add some padding for better appearance
//   border-radius: 5px; // Soft rounded edges
//   border: 2px solid #454649; // A border similar to other elements
//   outline: none; // To remove the browser default focus outline

//   // Style for the placeholder text
//   &::placeholder {
//     color: #adadad; // A lighter color for placeholder, adjust as needed
//     opacity: 1; // Firefox default is 0.54
//   }

//   // Styling when the input is focused
//   &:focus {
//     border-color: #0ea5e9; // Highlighting the border when focused, adjust color as needed
//   }
// `;

export const VerticalOptionsContainer = styled(OptionsContainer)`
  grid-template-columns: 1fr;
  grid-gap: 10px;
  justify-items: start; // This will align the items to the left.
`;

// const OptionLabelContainer = styled.div`
//   padding: 10px;
//   margin-bottom: 20px;
//   box-sizing: border-box;
//   width: auto;
//   background: #2a2b2f;
//   border: 3px solid #454649;
//   border-radius: 12px;
//   font-size: 1.5em;
// `;

export const OptionLabelContainer = styled.div`
  display: grid;
  gap: 1rem;
  box-sizing: border-box;
  border-radius: 12px;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  padding: 20px;
  border: 1px solid #454649;
  background-color: #2a2b2f;
  position: sticky;
  top: 0;
  z-index: 1; // ensure it stays on top of other content
  font-size: 1.5em;
  margin-bottom: 20px; // to add some space between two containers
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
