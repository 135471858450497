import React from "react";
import styled from "styled-components";
import footerImage from "../images/footerImage.png"; // replace with the path to your header image

const FooterContainer = styled.div`
  /* add styling for your header here */
  max-width: 860px;
`;

const Image = styled.img`
  /* add styling for your header image here */
  width: 100%;
  height: auto;
`;

function Header() {
  return (
    <FooterContainer>
      <Image src={footerImage} alt="footer" />
    </FooterContainer>
  );
}

export default Header;
